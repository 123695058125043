import {
    Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import * as Yup from 'yup';
import React, { useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import { Field, Form, Formik } from "formik";
import { maskCPF } from "utils/masks";
import { RiEyeCloseLine } from "react-icons/ri";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { lightFormat } from "date-fns";
import { MaskCNPJ } from "utils/masks";
import { maskPhone } from "utils/masks";
import { MaskCep } from "utils/masks";
import { CreateUser } from "services/users";
import { EditUser } from "services/users";
import { BlockUser } from "services/users";
import { DeleteUser } from "services/users";
import { useAuthContext } from "contexts/SidebarContext";
import Pagination from "components/pagination";
export default function ColumnsTable(props) {
    const { columnsData, tableData, getList, totalPages, currentPage, setCurrentPage } = props;

    const { user } = useAuthContext();
    const columns = useMemo(() => columnsData, [columnsData]);
    const data = useMemo(() => tableData, [tableData]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const tableInstance = useTable(
        {
        columns,
        data,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        initialState,
    } = tableInstance;
    initialState.pageSize = 10;

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
    const brandStars = useColorModeValue("brand.500", "brand.400");
    const textColorSecondary = "gray.400";

    const [isOpen, setIsOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [infoRow, setInfoRow] = useState(null);
    const [typeUser, setTypeUser] = useState('cpf');

    const handleClick = () => setShow(!show);

    const onClose = () => {
        setIsOpen(false);
        setInfoRow(null);
        setTypeUser('cpf');
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório'),
        document: Yup.string().required('Documento é obrigatório'),
        telephone: Yup.string().required('Telefone é obrigatório'),
        cep: Yup.string().required('CEP é obrigatório'),
        address: Yup.string().required('Endereço é obrigatório'),
        city: Yup.string().required('Cidade é obrigatória'),
        neighborhood: Yup.string().required('Bairro é obrigatório'),
        district: Yup.string().required('Estado é obrigatório'),
        numberAddress: Yup.string().notRequired(),
        birthDate: Yup.string().required('Data de nascimento é obrigatária'),
        email: Yup.string().email('E-mail inválido').required('E-mail é obrigatório'),
        password: Yup.string(),
    });

    const onClickEdit = (item) => {
        setTypeUser(item.type === 'PF' ? 'cpf' : 'cnpj');
        setInfoRow(item);
        setIsOpen(true);
    };

    const onClickBlock = async (item) => {
        await BlockUser(item.id, user?.token);
        getList();
    };

    const onClickDelete = async (item) => {
        await DeleteUser(item.id, user?.token);
        getList();
    };

  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Button onClick={() => setIsOpen(true)} w='200px' h='44px' mb='10px' variant='brand' alignSelf="flex-end" mr="10px">
            Criar usuário
        </Button>

        <Modal isOpen={isOpen} onClose={onClose} isCentered scrollBehavior="inside" size="2xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    {infoRow ? 'Editar usuário' : 'Criar usuário'}
                    <Text color='secondaryGray.700' fontSize='xs' fontWeight='400'>{`Preencha as informações abaixo para ${infoRow ? 'editar' : 'criar'} um usuário`}</Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Formik
                        initialValues={{ name: infoRow?.name || '', document: infoRow?.document || '', telephone: infoRow?.telephone || '', cep: infoRow?.cep || '', address: infoRow?.address || '', city: infoRow?.city || '', neighborhood: infoRow?.neighborhood || '', district: infoRow?.district || '', numberAddress: infoRow?.number_address || '', birthDate: infoRow?.birth_date || '', email: infoRow?.email || '', password: '' }}
                        validationSchema={validationSchema}
                        onSubmit={async (values, helpers) => {
                            if ((typeUser === 'cpf' && values.document.length !== 14) || (typeUser === 'cnpj' && values.document.length !== 18)) {
                              helpers.setStatus({ success: false });
                              helpers.setErrors({ submit: `Digite o ${typeUser} corretamente.` });
                              helpers.setSubmitting(false);
                              return null;
                            }
                            if (!values.password && !infoRow?.id) {
                                helpers.setStatus({ success: false });
                                helpers.setErrors({ submit: 'A senha é obrigatória na criação de usuário' });
                                helpers.setSubmitting(false);
                                return null;
                            }
                            try {
                                const response = !infoRow?.id ? await CreateUser(
                                    typeUser === 'cpf' ? 'PF' : 'PJ',
                                    values.name,
                                    values.document,
                                    values.telephone,
                                    values.cep,
                                    values.address,
                                    values.city,
                                    values.neighborhood,
                                    values.district,
                                    values.numberAddress,
                                    values.birthDate,
                                    values.email,
                                    values.password,
                                    user?.token,
                                ) : await EditUser(
                                    typeUser === 'cpf' ? 'PF' : 'PJ',
                                    values.name,
                                    values.document,
                                    values.telephone,
                                    values.cep,
                                    values.address,
                                    values.city,
                                    values.neighborhood,
                                    values.district,
                                    values.numberAddress,
                                    values.birthDate,
                                    values.email,
                                    values.password,
                                    infoRow?.id,
                                    user?.token,
                                );
                                if (response?.status === 200 || response?.status === 201) {
                                    helpers.setSubmitting(false);
                                    onClose();
                                    getList();
                                } else {
                                    helpers.setStatus({ success: false });
                                    helpers.setErrors({ submit: response?.message });
                                    helpers.setSubmitting(false);
                                }
                            } catch (err) {
                                helpers.setStatus({ success: false });
                                helpers.setErrors({ submit: err.message });
                                helpers.setSubmitting(false);
                            }
                        }}
                    >
                        {(props) => (
                            <Form style={{ display: 'flex', flexDirection: 'column' }}>
                                <RadioGroup
                                    onChange={(nextValue) => {
                                        setTypeUser(nextValue);
                                        if (nextValue === 'cpf') {
                                            props.setValues({
                                                name: '',
                                                cpf: '',
                                                telephone: '',
                                                cep: '',
                                                address: '',
                                                city: '',
                                                neighborhood: '',
                                                district: '',
                                                numberAddress: '',
                                                birthDate: '',
                                                email: '',
                                                password: ''
                                            });
                                        } else {
                                            props.setValues({
                                                nameCompany: '',
                                                cnpj: '',
                                                telephone: '',
                                                cep: '',
                                                address: '',
                                                city: '',
                                                neighborhood: '',
                                                district: '',
                                                numberAddress: '',
                                                birthDate: '',
                                                email: '',
                                                password: ''
                                            });
                                        }
                                    }}
                                    value={typeUser}
                                    mb="24px"
                                >
                                    <Stack direction='row'>
                                        <Radio value='cpf'>CPF</Radio>
                                        <Radio value='cnpj'>CNPJ</Radio>
                                    </Stack>
                                </RadioGroup>
                                <Grid templateColumns='repeat(4, 1fr)' gap='16px'>
                                    <Field name='name'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.name && form.touched.name}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    {typeUser === 'cpf' ? 'Nome' : 'Nome empresarial'}<Text color={brandStars}>*</Text>
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='Seu nome'
                                                    fontWeight='500'
                                                    size='lg'
                                                />
                                                <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name="document">
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.document && form.touched.document}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    {typeUser === 'cpf' ? 'CPF' : 'CNPJ'}<Text color={brandStars}>*</Text>
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='Seu documento'
                                                    fontWeight='500'
                                                    size='lg'
                                                    disabled={!!infoRow?.id}
                                                    onChange={(txt) => {
                                                        if (typeUser === 'cpf') {
                                                            const formatCPF = maskCPF(txt.target.value);
                                                            if (formatCPF.length <= 14) {
                                                                props.handleChange({
                                                                    target: {
                                                                        name: 'document',
                                                                        value: formatCPF,
                                                                    }
                                                                });
                                                            }
                                                        } else {
                                                            const formatCNPJ = MaskCNPJ(txt.target.value);
                                                            props.handleChange({
                                                                target: {
                                                                    name: 'document',
                                                                    value: formatCNPJ,
                                                                }
                                                            });
                                                        }
                                                    }}
                                                />
                                                <FormErrorMessage>{form.errors.document}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name='telephone'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.telephone && form.touched.telephone}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    Telefone<Text color={brandStars}>*</Text>
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='Seu telefone'
                                                    fontWeight='500'
                                                    size='lg'
                                                    onChange={(txt) => {
                                                        const formatPhone = maskPhone(txt.target.value);
                                                        props.handleChange({
                                                            target: {
                                                                name: "telephone",
                                                                value: formatPhone,
                                                            }
                                                        });
                                                    }}
                                                />
                                                <FormErrorMessage>{form.errors.telephone}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name='cep'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.cep && form.touched.cep}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    CEP<Text color={brandStars}>*</Text>
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='Seu CEP'
                                                    fontWeight='500'
                                                    size='lg'
                                                    onChange={(txt) => {
                                                        const formatCep = MaskCep(txt.target.value);
                                                        props.handleChange({
                                                            target: {
                                                                name: "cep",
                                                                value: formatCep,
                                                            }
                                                        });
                                                    }}
                                                />
                                                <FormErrorMessage>{form.errors.cep}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name='address'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.address && form.touched.address}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    Endereço<Text color={brandStars}>*</Text>
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='Seu endereço'
                                                    fontWeight='500'
                                                    size='lg'
                                                />
                                                <FormErrorMessage>{form.errors.address}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>

                                    <Field name='city'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.city && form.touched.city}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    Cidade<Text color={brandStars}>*</Text>
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='Sua cidade'
                                                    fontWeight='500'
                                                    size='lg'
                                                />
                                                <FormErrorMessage>{form.errors.city}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name='neighborhood'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.neighborhood && form.touched.neighborhood}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    Bairro<Text color={brandStars}>*</Text>
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='Seu bairro'
                                                    fontWeight='500'
                                                    size='lg'
                                                />
                                                <FormErrorMessage>{form.errors.neighborhood}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name='district'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.district && form.touched.district}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    Estado<Text color={brandStars}>*</Text>
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='Seu estado'
                                                    fontWeight='500'
                                                    size='lg'
                                                />
                                                <FormErrorMessage>{form.errors.district}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name='numberAddress'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.numberAddress && form.touched.numberAddress}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    Número
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='Seu número'
                                                    fontWeight='500'
                                                    size='lg'
                                                />
                                                <FormErrorMessage>{form.errors.numberAddress}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name='birthDate'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.birthDate && form.touched.birthDate}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    Data de nascimento<Text color={brandStars}>*</Text>
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='Sua data de nascimento'
                                                    fontWeight='500'
                                                    size='lg'
                                                    type="date"
                                                />
                                                <FormErrorMessage>{form.errors.birthDate}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name='email'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.email && form.touched.email}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    E-mail<Text color={brandStars}>*</Text>
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    type='email'
                                                    placeholder='Seu e-mail'
                                                    fontWeight='500'
                                                    size='lg'
                                                />
                                                <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name='password' style={{ gridColumn: 'span 2' }}>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.password && form.touched.password}>
                                                <FormLabel
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    display='flex'>
                                                    Senha<Text color={brandStars}>*</Text>
                                                </FormLabel>
                                                <InputGroup size='md'>
                                                    <Input
                                                        {...field}
                                                        fontSize='sm'
                                                        placeholder='Sua senha'
                                                        size='lg'
                                                        type={show ? "text" : "password"}
                                                        variant='auth'
                                                    />
                                                    <InputRightElement display='flex' alignItems='center' mt='4px'>
                                                        <Icon
                                                            color={textColorSecondary}
                                                            _hover={{ cursor: "pointer" }}
                                                            as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                                            onClick={handleClick}
                                                        />
                                                    </InputRightElement>
                                                </InputGroup>
                                                <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </Grid>
                                {props.errors.submit && (
                                    <Text
                                        color={props.status.success ? 'green.500' : 'red.500'}
                                        fontSize='small'
                                        fontWeight='400'
                                        marginTop="20px"
                                    >
                                        {props.errors.submit}
                                    </Text>
                                )}
                                <Button
                                    variant='brand'
                                    isLoading={props.isSubmitting}
                                    type="submit"
                                    alignSelf="flex-end"
                                    w="150px"
                                    marginTop="24px"
                                >
                                    {infoRow?.id ? 'Editar usuário' : 'Criar usuário'}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>

                <ModalFooter>
                </ModalFooter>
            </ModalContent>
        </Modal>
      <Table {...getTableProps()} variant='simple' color='gray.500' mb="100px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='10px'
                  key={index}
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "AÇÕES") {
                    return (
                        <Flex align='center' pl="30px" pt="5px">
                            <Menu row={cell.row.original} onClickEdit={onClickEdit} onClickBlock={onClickBlock} onClickDelete={onClickDelete} />
                        </Flex>
                    )
                  } else if (cell.column.Header === "NOME") {
                    data = (
                      <Flex align='center'>
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "TELEFONE") {
                    data = (
                      <Flex align='center'>
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "E-MAIL") {
                    data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                            {cell.value}
                        </Text>
                    );
                  } else if (cell.column.Header === "CPF/CNPJ") {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "SITUAÇÃO") {
                    data = (
                      <Text color={cell.value ? 'red.500' : 'green.500'} fontSize='sm' fontWeight='700'>
                        {cell.value ? 'Inativo' : 'Ativo'}
                      </Text>
                    );
                  } else if (cell.column.Header === "CRIADO EM") {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {lightFormat(new Date(cell.value), 'dd/MM/yyyy')}
                      </Text>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor='transparent'>
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
        <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
        />
    </Card>
  );
}
