import React, { useState } from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
//   Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
// import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
// import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useAuth } from 'hooks/use-auth';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { maskCPF, MaskCep, MaskCNPJ } from "utils/masks";
import { CreateUser } from "services/users";
import { maskPhone } from "utils/masks";

function SignUp() {
    const auth = useAuth();

    const gridColumnCount = useBreakpointValue({ base: 1, lg: 4 });
    const mbCount = useBreakpointValue({ base: 10, lg: 0 });

    // Chakra color mode
    const textColor = useColorModeValue("navy.700", "white");
    const textColorSecondary = "gray.400";
    const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
    const textColorBrand = useColorModeValue("brand.500", "white");
    const brandStars = useColorModeValue("brand.500", "brand.400");
    // const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
    // const googleText = useColorModeValue("navy.700", "white");
    // const googleHover = useColorModeValue(
    //     { bg: "gray.200" },
    //     { bg: "whiteAlpha.300" }
    // );
    // const googleActive = useColorModeValue(
    //     { bg: "secondaryGray.300" },
    //     { bg: "whiteAlpha.200" }
    // );
    const [show, setShow] = useState(false);
    const [typeUser, setTypeUser] = useState('cpf');
    const [isChecked, setIsChecked] = useState(false);
    const handleClick = () => setShow(!show);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório'),
        document: Yup.string().required('Documento é obrigatório'),
        telephone: Yup.string().required('Telefone é obrigatório'),
        cep: Yup.string().required('CEP é obrigatório'),
        address: Yup.string().required('Endereço é obrigatório'),
        city: Yup.string().required('Cidade é obrigatória'),
        neighborhood: Yup.string().required('Bairro é obrigatório'),
        district: Yup.string().required('Estado é obrigatório'),
        numberAddress: Yup.string().notRequired(),
        birthDate: Yup.string().required('Data de nascimento é obrigatária'),
        email: Yup.string().email('E-mail inválido').required('E-mail é obrigatório'),
        password: Yup.string().required('Senha é obrigatória'),
    });

    const handleChange = () => {
        setIsChecked(!isChecked);
    };

    return (
        <DefaultAuth illustrationBackground={illustration} image={illustration}>
            <Flex
                minW='100%'
                h='100%'
                display="flex"
                flexDirection="column"
                justifyContent='center'
                alignItems="center"
            >
                <Box overflowY="auto" maxH="100%" width="90%">
                    <Heading color={textColor} fontSize='36px' mb='10px'>
                        Cadastro
                    </Heading>
                    <Text
                        mb='36px'
                        ms='4px'
                        color={textColorSecondary}
                        fontWeight='400'
                        fontSize='md'
                    >
                        Preencha as informações abaixo para se cadastrar.
                    </Text>
                    <Formik
                        initialValues={{ name: '', document: '', telephone: '', cep: '', address: '', city: '', neighborhood: '', district: '', numberAddress: '', birthDate: '', email: '', password: '' }}
                        validationSchema={validationSchema}
                        onSubmit={async (values, helpers) => {
                            if ((typeUser === 'cpf' && values.document.length !== 14) || (typeUser === 'cnpj' && values.document.length !== 18)) {
                              helpers.setStatus({ success: false });
                              helpers.setErrors({ submit: `Digite o ${typeUser} corretamente.` });
                              helpers.setSubmitting(false);
                              return null;
                            }
                            if (!isChecked) {
                                helpers.setStatus({ success: false });
                                helpers.setErrors({ submit: 'Aceite os termos de uso e política de privacidade antes de prosseguir' });
                                helpers.setSubmitting(false);
                                return null;
                            }
                            if (!values.password) {
                                helpers.setStatus({ success: false });
                                helpers.setErrors({ submit: 'A senha é obrigatória na criação de usuário' });
                                helpers.setSubmitting(false);
                                return null;
                            }
                            try {
                                const response = await CreateUser(
                                    typeUser === 'cpf' ? 'PF' : 'PJ',
                                    values.name,
                                    values.document,
                                    values.telephone,
                                    values.cep,
                                    values.address,
                                    values.city,
                                    values.neighborhood,
                                    values.district,
                                    values.numberAddress,
                                    values.birthDate,
                                    values.email,
                                    values.password,
                                );
                                if (response?.status === 200 || response?.status === 201) {
                                    helpers.setSubmitting(false);
                                    await auth.updateDataUser({ ...response.result, user: { ...response.result.user, typeUser: 'users' } });
                                    // window.location.replace('/');
                                } else {
                                    helpers.setStatus({ success: false });
                                    helpers.setErrors({ submit: response?.message });
                                    helpers.setSubmitting(false);
                                }
                            } catch (err) {
                                helpers.setStatus({ success: false });
                                helpers.setErrors({ submit: err.message });
                                helpers.setSubmitting(false);
                            }
                        }}
                    >
                        {(props) => (
                            <Form style={{ display: 'flex', flexDirection: 'column' }}>
                                <RadioGroup
                                    onChange={(nextValue) => {
                                        setTypeUser(nextValue);
                                        if (nextValue === 'cpf') {
                                            props.setValues({
                                                name: '',
                                                cpf: '',
                                                telephone: '',
                                                cep: '',
                                                address: '',
                                                city: '',
                                                neighborhood: '',
                                                district: '',
                                                numberAddress: '',
                                                birthDate: '',
                                                email: '',
                                                password: ''
                                            });
                                        } else {
                                            props.setValues({
                                                nameCompany: '',
                                                cnpj: '',
                                                telephone: '',
                                                cep: '',
                                                address: '',
                                                city: '',
                                                neighborhood: '',
                                                district: '',
                                                numberAddress: '',
                                                birthDate: '',
                                                email: '',
                                                password: ''
                                            });
                                        }
                                    }}
                                    value={typeUser}
                                    mb="24px"
                                >
                                    <Stack direction='row'>
                                        <Radio value='cpf'>CPF</Radio>
                                        <Radio value='cnpj'>CNPJ</Radio>
                                    </Stack>
                                </RadioGroup>
                                <Grid templateColumns={`repeat(${gridColumnCount}, 1fr)`} gap='16px'>
                                    <Field name='name'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.name && form.touched.name}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    {typeUser === 'cpf' ? 'Nome' : 'Nome empresarial'}<Text color={brandStars}>*</Text>
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='Seu nome'
                                                    fontWeight='500'
                                                    size='lg'
                                                />
                                                <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name="document">
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.document && form.touched.document}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    {typeUser === 'cpf' ? 'CPF' : 'CNPJ'}<Text color={brandStars}>*</Text>
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='Seu documento'
                                                    fontWeight='500'
                                                    size='lg'
                                                    onChange={(txt) => {
                                                        if (typeUser === 'cpf') {
                                                            const formatCPF = maskCPF(txt.target.value);
                                                            if (formatCPF.length <= 14) {
                                                                props.handleChange({
                                                                    target: {
                                                                        name: 'document',
                                                                        value: formatCPF,
                                                                    }
                                                                });
                                                            }
                                                        } else {
                                                            const formatCNPJ = MaskCNPJ(txt.target.value);
                                                            props.handleChange({
                                                                target: {
                                                                    name: 'document',
                                                                    value: formatCNPJ,
                                                                }
                                                            });
                                                        }
                                                    }}
                                                />
                                                <FormErrorMessage>{form.errors.document}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name='telephone'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.telephone && form.touched.telephone}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    Telefone<Text color={brandStars}>*</Text>
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='Seu telefone'
                                                    fontWeight='500'
                                                    size='lg'
                                                    onChange={(txt) => {
                                                        const formatPhone = maskPhone(txt.target.value);
                                                        props.handleChange({
                                                            target: {
                                                                name: "telephone",
                                                                value: formatPhone,
                                                            }
                                                        });
                                                    }}
                                                />
                                                <FormErrorMessage>{form.errors.telephone}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name='cep'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.cep && form.touched.cep}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    CEP<Text color={brandStars}>*</Text>
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='Seu CEP'
                                                    fontWeight='500'
                                                    size='lg'
                                                    onChange={(txt) => {
                                                        const formatCep = MaskCep(txt.target.value);
                                                        props.handleChange({
                                                            target: {
                                                                name: "cep",
                                                                value: formatCep,
                                                            }
                                                        });
                                                    }}
                                                />
                                                <FormErrorMessage>{form.errors.cep}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name='address'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.address && form.touched.address}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    Endereço<Text color={brandStars}>*</Text>
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='Seu endereço'
                                                    fontWeight='500'
                                                    size='lg'
                                                />
                                                <FormErrorMessage>{form.errors.address}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name='city'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.city && form.touched.city}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    Cidade<Text color={brandStars}>*</Text>
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='Sua cidade'
                                                    fontWeight='500'
                                                    size='lg'
                                                />
                                                <FormErrorMessage>{form.errors.city}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name='neighborhood'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.neighborhood && form.touched.neighborhood}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    Bairro<Text color={brandStars}>*</Text>
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='Seu bairro'
                                                    fontWeight='500'
                                                    size='lg'
                                                />
                                                <FormErrorMessage>{form.errors.neighborhood}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name='district'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.district && form.touched.district}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    Estado<Text color={brandStars}>*</Text>
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='Seu estado'
                                                    fontWeight='500'
                                                    size='lg'
                                                />
                                                <FormErrorMessage>{form.errors.district}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name='numberAddress'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.numberAddress && form.touched.numberAddress}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    Número
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='Seu número'
                                                    fontWeight='500'
                                                    size='lg'
                                                />
                                                <FormErrorMessage>{form.errors.numberAddress}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name='birthDate'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.birthDate && form.touched.birthDate}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    Data de nascimento<Text color={brandStars}>*</Text>
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='Sua data de nascimento'
                                                    fontWeight='500'
                                                    size='lg'
                                                    type="date"
                                                />
                                                <FormErrorMessage>{form.errors.birthDate}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name='email'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.email && form.touched.email}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    E-mail<Text color={brandStars}>*</Text>
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    type='email'
                                                    placeholder='Seu e-mail'
                                                    fontWeight='500'
                                                    size='lg'
                                                />
                                                <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name='password' style={{ gridColumn: 'span 2' }}>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.password && form.touched.password}>
                                                <FormLabel
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    display='flex'>
                                                    Senha<Text color={brandStars}>*</Text>
                                                </FormLabel>
                                                <InputGroup size='md'>
                                                    <Input
                                                        {...field}
                                                        fontSize='sm'
                                                        placeholder='Sua senha'
                                                        size='lg'
                                                        type={show ? "text" : "password"}
                                                        variant='auth'
                                                    />
                                                    <InputRightElement display='flex' alignItems='center' mt='4px'>
                                                        <Icon
                                                            color={textColorSecondary}
                                                            _hover={{ cursor: "pointer" }}
                                                            as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                                            onClick={handleClick}
                                                        />
                                                    </InputRightElement>
                                                </InputGroup>
                                                <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </Grid>
                                {props.errors.submit && (
                                    <Text
                                        color={props.status.success ? 'green.500' : 'red.500'}
                                        fontSize='small'
                                        fontWeight='400'
                                        marginTop="24px"
                                    >
                                        {props.errors.submit}
                                    </Text>
                                )}
                                <Flex
                                    justifyContent='center'
                                    alignItems='start'
                                    maxW='100%'
                                    mt='0px'
                                    marginTop="24px"
                                    display="flex"
                                    alignSelf="flex-start"
                                >
                                    <Checkbox
                                        colorScheme='brandScheme'
                                        me='10px'
                                        isChecked={isChecked}
                                        onChange={handleChange}
                                    />
                                    <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
                                        Eu concordo com os
                                        <a target="_blank" rel="noopener noreferrer" href='https://dsstoken.sfo3.digitaloceanspaces.com/documents/termos-de-uso.pdf'>
                                            <Text
                                                color={textColorBrand}
                                                as='span'
                                                ms='5px'
                                                mr="5px"
                                                fontWeight='500'
                                            >
                                                Termos de uso
                                            </Text>
                                        </a>
                                        e a
                                        <a target="_blank" rel="noopener noreferrer" href='https://dsstoken.sfo3.digitaloceanspaces.com/documents/privacidade.pdf'>
                                            <Text
                                                color={textColorBrand}
                                                as='span'
                                                ms='5px'
                                                fontWeight='500'
                                            >
                                                Política de Privacidade
                                            </Text>
                                        </a>
                                    </Text>
                                </Flex>
                                <Button
                                    fontSize='sm'
                                    variant='brand'
                                    fontWeight='500'
                                    w='50%'
                                    h='50'
                                    mt='24px'
                                    mb="24px"
                                    isLoading={props.isSubmitting}
                                    type="submit"
                                >
                                    Cadastrar-se
                                </Button>
                            </Form>
                        )}
                    </Formik>
                    <Flex
                        flexDirection='column'
                        justifyContent='center'
                        alignItems='start'
                        maxW='100%'
                        mt='0px'
                        mb={mbCount}
                    >
                        <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
                            Já tem uma conta?
                            <NavLink to='/auth/sign-in'>
                                <Text
                                    color={textColorBrand}
                                    as='span'
                                    ms='5px'
                                    fontWeight='500'
                                >
                                    Acesse aqui
                                </Text>
                            </NavLink>
                        </Text>
                    </Flex>
                </Box>
                {/* <Flex
                    zIndex='2'
                    direction='column'
                    w={{ base: "100%", md: "700px" }}
                    maxW='100%'
                    background='transparent'
                    borderRadius='15px'
                    mx={{ base: "auto", lg: "unset" }}
                    me='auto'
                    mb={{ base: "20px", md: "auto" }}
                >
                </Flex> */}
            </Flex>
        </DefaultAuth>
    );
}

export default SignUp;
