/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// Chakra imports
import {
  Box,
  Flex,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

// Custom components
import Card from "components/card/Card.js";

// Assets
import { useAuthContext } from "contexts/SidebarContext";
import Pagination from "components/pagination";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { ListUniqueTokens } from "services/tokens";
import { lightFormat } from "date-fns";
import { toPrice } from "utils/masks";

export default function Negociations() {
    const { user } = useAuthContext();
    const textColor = useColorModeValue("brands.900", "white");
    const bgItem = useColorModeValue(
      { bg: "white", boxShadow: "0px 40px 58px -20px rgba(112, 144, 176, 0.12)" },
      { bg: "navy.700", boxShadow: "unset" }
    );

    const [listHash, setListHash] = useState([]);
    const [totalPagesHash, setTotalPagesHash] = useState(1);
    const [currentPageHash, setCurrentPageHash] = useState(1);
    const [searchTxtHash, setSearchTxtHash] = useState('');

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            getListUnique();
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [currentPageHash, searchTxtHash]);

    const handlePageChangeHash = (pageNumber) => {
        setCurrentPageHash(pageNumber);
    };

    const getListUnique = async () => {
        const response = await ListUniqueTokens(user?.user?.id, searchTxtHash, currentPageHash, user?.token);
        setListHash(response?.result?.list || []);
        setTotalPagesHash(response?.result?.qtdPages || 1);
    };


  // Chakra Color Mode
  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
        <Flex
          flexDirection='column'
          gridArea={{ xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3" }}>
          <Card p='0px 0px 20px 0px'>
              <Flex justifyContent="space-between" padding="20px 20px 0px 20px" mb="20px">
                  <SearchBar
                      placeholder="Buscar hash"
                      value={searchTxtHash}
                      setValue={setSearchTxtHash}
                  />
              </Flex>
              <Flex
                  direction='flex'
                  justifyContent="space-between"
                  w="90%"
                  alignSelf="center"
                  mb="10px"
                  // pl="20px"
              >
                  {["DATA", "QTD", "VALOR", "OPERAÇÃO", "ORIGEM", "USUÁRIO", "TOKEN"]?.map((v) => (
                      <Text
                          color={textColor}
                          fontSize={{
                              base: "md",
                          }}
                          fontWeight='bold'
                          key={v}
                          maxW="14%"
                          minW="14%"
                      >
                          {v}
                      </Text>
                  ))}
              </Flex>
              {(listHash || []).map((v) => {
              const qtd = Math.max(v.token_qtd_order_current, v.token_qtd_order_old) - Math.min(v.token_qtd_order_current, v.token_qtd_order_old);
                return (
                  <Card
                      _hover={bgItem}
                      bg='transparent'
                      boxShadow='unset'
                      // px='24px'
                      // py='21px'
                      transition='0.2s linear'
                      style={{ cursor: 'pointer' }}
                      key={v.id}
                      w="90%"
                      alignSelf="center"
                      p="0px"
                      pb="10px"
                  >
                      <Flex
                          direction='flex'
                          justifyContent="space-between"
                          w="100%"
                          alignSelf="center"
                      >
                          <Text
                              color={textColor}
                              fontSize={{
                                  base: "md",
                              }}
                              fontWeight='bold'
                              maxW="14%"
                              minW="14%"
                          >
                              {lightFormat(new Date(v.created_at), 'dd/MM/yyyy HH:mm')}
                          </Text>
                          <Text
                              color={textColor}
                              fontSize={{
                                  base: "md",
                              }}
                              fontWeight='bold'
                              maxW="14%"
                              minW="14%"
                          >
                              {qtd}
                          </Text>
                          <Text
                              color={textColor}
                              fontSize={{
                                  base: "md",
                              }}
                              fontWeight='bold'
                              maxW="14%"
                              minW="14%"
                          >
                            {`R$ ${toPrice(v.token_value_unity * qtd, 2)}`}
                          </Text>
                          <Text
                              color={textColor}
                              fontSize={{
                                  base: "md",
                              }}
                              fontWeight='bold'
                              maxW="14%"
                              minW="14%"
                          >
                              {v.type === 'buy' ? 'Compra' : 'Venda'}
                          </Text>
                          <Text
                              color={textColor}
                              fontSize={{
                                  base: "md",
                              }}
                              fontWeight='bold'
                              maxW="14%"
                              minW="14%"
                          >
                              {v.platform === 'web' ? 'Plataforma' : 'Exchange'}
                          </Text>
                          <Text
                              color={textColor}
                              fontSize={{
                                  base: "md",
                              }}
                              fontWeight='bold'
                              maxW="14%"
                              minW="14%"
                          >
                              {v.users_name}
                          </Text>
                          <Text
                              color={textColor}
                              fontSize={{
                                  base: "md",
                              }}
                              fontWeight='bold'
                              maxW="14%"
                              minW="14%"
                          >
                              {v.tokens_name}
                          </Text>
                      </Flex>
                  </Card>
                );
              })}
              <Pagination
                  currentPage={currentPageHash}
                  totalPages={totalPagesHash}
                  onPageChange={handlePageChangeHash}
                  limit={30}
              />
          </Card>
        </Flex>
    </Box>
  );
}
