/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// Chakra imports
import {
  Box,
  Flex,
  FormControl,
  Input,
  Text,
} from "@chakra-ui/react";

// Custom components
import HistoryItem from "views/client/tokens/components/HistoryItem";
import Card from "components/card/Card.js";

// Assets
import { useAuthContext } from "contexts/SidebarContext";
import Pagination from "components/pagination";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { ModalOrder } from "components/modalOrder";
import { ListTokensExchange } from "services/tokens";
import Autocomplete from "react-autocomplete";
import { ListUsers } from "services/users";

export default function Tokens() {
    const { user } = useAuthContext();

    const isAdm = window.location.href.includes('/admin/wallet');

    const [list, setList] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTxt, setSearchTxt] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [infoToken, setInfoToken] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [step, setStep] = useState(1);
    const [usersAutoComplete, setUsersAutoComplete] = useState('');
    const [usersSearch, setUsersSearch] = useState('');
    const [listUsers, setListUsers] = useState([]);

    useEffect(() => {
      if (usersAutoComplete || !isAdm) {
        const timeOutId = setTimeout(() => {
            getList();
        }, 500);
        return () => clearTimeout(timeOutId);
      }
    }, [currentPage, searchTxt, usersAutoComplete]);

    useEffect(() => {
      if (isAdm) {
        const timeOutId = setTimeout(() => {
            getListUsers();
        }, 500);
        return () => clearTimeout(timeOutId);
      }
    }, [usersSearch, isAdm]);
  
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    
    const getListUsers = async () => {
      const response = await ListUsers(null, usersSearch, 1, 5, user?.token);
      setListUsers(response?.result?.list || []);
    };

    const getList = async () => {
        const response = await ListTokensExchange(isAdm ? usersAutoComplete : user?.user?.id, searchTxt, currentPage, user?.token);
        setList(response?.result?.list || []);
        setTotalPages(response?.result?.qtdPages || 1);
    };

    const handleClickContinueOrder = (item) => {
        if (item.status === 'NO_PAID') {
            setIsOpen(true);
            setInfoToken(item);
            setStep(3);
        }
        if (item.status === 'NO_SIGNATURE') {
            setIsOpen(true);
            setInfoToken(item);
            setStep(2);
        }
    };

    const renderStatus = (status) => {
        if (status === 'NO_SIGNATURE') {
            return 'Não assinado';
        }
        if (status === 'NO_PAID') {
            return 'Não pago';
        }
        if (status === 'PAID') {
            return 'Pago';
        }
        if (status === 'AUTHORIZED') {
            return 'Pagamento em processamento';
        }
        if (status === 'CANCELED') {
            return 'Pagamento cancelado';
        }
        if (status === 'ABORT') {
            return 'Pedido cancelado pelo cliente';
        }
        return '';
    };

  // Chakra Color Mode
  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
        <Flex
          flexDirection='column'
          gridArea={{ xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3" }}>
            <Card p='0px 0px 20px 0px'>
                <Flex justifyContent="space-between" padding="20px 20px 0px 20px" mb="20px" gap="10px">
                  <SearchBar
                      style={{ marginLeft: 20 }}
                      placeholder="Buscar token"
                      value={searchTxt}
                      setValue={setSearchTxt}
                  />
                  {!isAdm ? null : (
                    <FormControl>
                      <Autocomplete
                          getItemValue={(item) => item.id}
                          items={listUsers}
                          renderItem={(item, isHighlighted) =>
                              <Text cursor="pointer" color={isHighlighted ? 'blue.500' : 'secondaryGray.700'} fontSize='sm' fontWeight='400' p="2px 5px 2px 5px">{item.name}</Text>
                          }
                          renderInput={(props) => (
                              <Input
                                  {...props}
                                  variant='auth'
                                  fontSize='sm'
                                  ms={{ base: "0px", md: "0px" }}
                                  placeholder='Selecione um usuário'
                                  fontWeight='500'
                                  size='lg'
                                  value={usersSearch}
                                  onChange={(e) => setUsersSearch(e.target.value)}
                              />
                          )}
                          onSelect={(val, item) => {
                              setUsersAutoComplete(val);
                              setUsersSearch(item.name);
                          }}
                      />
                    </FormControl>
                  )}
                </Flex>
                {(list || []).map((v) => (
                    <HistoryItem
                        name={v.token.name}
                        author={`Situação: ${renderStatus(v.order.status)}`}
                        info={`Total de tokens adquiridos: ${v.total_qtd}`}
                        date={v.created_at}
                        image={v.token.logo}
                        price={v.total_value}
                        key={v.id}
                        onClick={() => {
                            // if (v.order.status === 'PAID') {
                            //     setInfoToken(v);
                            //     setFrame(2);
                            // }
                        }}
                        handleClickContinueOrder={handleClickContinueOrder}
                        getList={getList}
                        item={v}
                    />
                ))}
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
            </Card>
        </Flex>
        <ModalOrder isOpen={isOpen} setIsOpen={setIsOpen} infoToken={infoToken} setInfoToken={setInfoToken} getList={getList} isLoading={isLoading} setIsLoading={setIsLoading} step={step} setStep={setStep} />
    </Box>
  );
}
