import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineGeneratingTokens,
  MdWallet,
  MdLocalOffer,
  MdGavel,
  MdReceipt,
  MdImage,
  MdMonetizationOn,
} from "react-icons/md";

// Clients Imports
import OffersClient from "views/client/offers";
import TokensClient from "views/client/tokens";

// Admin Imports
import MainDashboard from "views/admin/default";
import Profile from "views/admin/profile";
import Users from "views/admin/users";
import Tokens from "views/admin/tokens";
import TokensSale from "views/admin/tokensSale";
import Leiloes from "views/admin/leiloes";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import SignUpCentered from "views/auth/signUp";
import forgotPasswordCentered from "views/auth/forgotPassword";
import Invoices from "views/admin/invoices";
import Banners from "views/admin/banners";
import { FaCog } from "react-icons/fa";
import Config from "views/admin/config";
import Financy from "views/admin/financy";
import Notifications from "views/admin/notifications";
import { IoMdNotifications } from "react-icons/io";
import ExcludedAccount from "views/auth/excludedAccount";
import PrivacyPolicy from "views/auth/privacy-policy";
import Negociations from "views/client/negociations";

export const formatRoutes = () => {
    const isAuthenticated = window.localStorage.getItem('@dsstoken_authenticated');

    const routesAuth = [
        {
            name: "Sign In",
            layout: "/auth",
            path: "/sign-in",
            icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
            component: SignInCentered,
            hidden: true,
        },
        {
            name: "Sign Up",
            layout: "/auth",
            path: "/sign-up",
            icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
            component: SignUpCentered,
            hidden: true,
        },
        {
            name: "Recover Password",
            layout: "/auth",
            path: "/forgot-password",
            icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
            component: forgotPasswordCentered,
            hidden: true,
        },
        {
            name: "Exclusão de conta",
            layout: "/auth",
            path: "/exclusao-de-conta",
            icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
            component: ExcludedAccount,
            hidden: true,
        },
        {
            name: "Política de privacidade",
            layout: "/auth",
            path: "/privacy-policy",
            icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
            component: PrivacyPolicy,
            hidden: true,
        },
    ];

    const routesAdm = [
        {
          name: "Dashboard",
          layout: "/admin",
          path: "/default",
          icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
          component: MainDashboard,
        },
        {
          name: "Tokens",
          layout: "/admin",
          icon: <Icon as={MdOutlineGeneratingTokens} width='20px' height='20px' color='inherit' />,
          path: "/tokens",
          component: Tokens,
        },
        {
            name: "Wallet",
            layout: "/admin",
            path: "/wallet",
            icon: <Icon as={MdWallet} width='20px' height='20px' color='inherit' />,
            component: TokensClient,
        },
        {
          name: "Negociações",
          layout: "/admin",
          icon: <Icon as={MdOutlineGeneratingTokens} width='20px' height='20px' color='inherit' />,
          path: "/negociacoes",
          component: TokensSale,
        },
        {
          name: "Leilões",
          layout: "/admin",
          icon: <Icon as={MdGavel} width='20px' height='20px' color='inherit' />,
          path: "/leiloes",
          component: Leiloes,
        },
        {
          name: "Faturas",
          layout: "/admin",
          icon: <Icon as={MdReceipt} width='20px' height='20px' color='inherit' />,
          path: "/faturas",
          component: Invoices,
        },
        {
          name: "Banners",
          layout: "/admin",
          icon: <Icon as={MdImage} width='20px' height='20px' color='inherit' />,
          path: "/banners",
          component: Banners,
        },
        {
          name: "Usuários",
          layout: "/admin",
          icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
          path: "/users",
          component: Users,
        },
        {
          name: "Financeiro",
          layout: "/admin",
          icon: <Icon as={MdMonetizationOn} width='20px' height='20px' color='inherit' />,
          path: "/financeiro",
          component: Financy,
        },
        {
          name: "Notificações",
          layout: "/admin",
          icon: <Icon as={IoMdNotifications} width='20px' height='20px' color='inherit' />,
          path: "/notifications",
          component: Notifications,
        },
        {
          name: "Configurações",
          layout: "/admin",
          icon: <Icon as={FaCog} width='20px' height='20px' color='inherit' />,
          path: "/configuracoes",
          component: Config,
        },
        // {
        //   name: "Configurações de perfil",
        //   layout: "/admin",
        //   path: "/profile",
        //   icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
        //   component: Profile,
        //   hidden: true,
        // },
    ];

    const routesClient = [
        {
            name: "Ofertas ativas",
            layout: "/client",
            path: "/offers",
            icon: <Icon as={MdLocalOffer} width='20px' height='20px' color='inherit' />,
            component: OffersClient,
        },
        {
            name: "Meus tokens",
            layout: "/client",
            path: "/tokens",
            icon: <Icon as={MdOutlineGeneratingTokens} width='20px' height='20px' color='inherit' />,
            component: TokensClient,
        },
        {
            name: "Minhas negociações",
            layout: "/client",
            path: "/negociacoes",
            icon: <Icon as={MdOutlineGeneratingTokens} width='20px' height='20px' color='inherit' />,
            component: Negociations,
        },
        {
          name: "Financeiro",
          layout: "/client",
          icon: <Icon as={MdMonetizationOn} width='20px' height='20px' color='inherit' />,
          path: "/financeiro",
          component: Financy,
        },
        {
            name: "Configurações de perfil",
            layout: "/client",
            path: "/profile",
            icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
            component: Profile,
            hidden: true,
        },
    ];

    if (isAuthenticated) {
        const dataUser = JSON.parse(isAuthenticated);
        return [
            ...routesAuth,
            ...(dataUser?.user?.typeUser === 'managers' ? routesAdm : routesClient),
        ];
    }
    
    return routesAuth;
}
